import React, { useState } from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import validator from 'validator';
import { useSelector } from 'react-redux';
import { getOptimize } from 'store';

const LeadForm = (props = {}) => {
  const {fields = {}, setField, nextStep, previousStep } = props;
  const {name = '', email = ''} = fields;

  const { homevalCTA } = useSelector(getOptimize);

  const updateNameField = (field) => (e) => {
    onNameFieldStatus(true);
    setField(field, e.target.value);
  }

  const updateEmailField = (field) => (e) => {
    onEmailFieldStatus(true);
    setField(field, e.target.value);
  }

  const isNameValid = (name) => {
    return (validator.isAlpha(name, 'en-US', { ignore: ' -' }));
  }
  
  const onSubmit = () => {

    let isNameValidated = false;
    let isEmailValidated = false;
  
    if (name && isNameValid(name)) {
      onNameFieldStatus(true);
      isNameValidated = true;
    } else {
      onNameFieldStatus(false);
    }
  
    if (email && validator.isEmail(email)) {
      onEmailFieldStatus(true);
      isEmailValidated = true;
    } else {
      onEmailFieldStatus(false);
    }
  
    if (isNameValidated && isEmailValidated) {
      nextStep();
    }
  };
  

  const [nameField, setNameField] = useState(true);
  const [emailField, setEmailField] = useState(true);

  const onNameFieldStatus = (state) => {
    setNameField(state);
  }

  const onEmailFieldStatus = (state) => {
    setEmailField(state);
  }
  
  const onBack = () => { previousStep(); };
  
  return (
    <div className='report-form'>
      <h1 className='title'>Your estimate is only a step away!</h1>
      <div className='input-wrapper'>
        <Input
          value={name}
          onChange={updateNameField('name')}
          placeholder={'Full Name'}
          dataCY={'full_name'}
          className={nameField ? null : 'input--invalid'}
        />
        <Input
          value={email}
          onChange={updateEmailField('email')}
          placeholder={'Email Address'}
          type={'email'}
          dataCY={'email'}
          className= { emailField ? null : 'input--invalid'}
        />
      </div>
      <Button
        text={ homevalCTA ? 'View Estimate & Connect With an Agent' : 'View Estimate'}
        onClick={onSubmit}
        dataCY={'search_cta'}
        classes={'cta-filled'}
      />
      <Button
        text={'Back'}
        onClick={onBack}
        classes={'back-button'}
      />
    </div>
  );
};

export default LeadForm;
