import React, { useState, useEffect } from 'react';
import { sendToDataLayer } from 'lib/logGTM';
import { hidePhoneNr } from 'utils/utils.js';
import { PHONE_VERIFY } from 'lib/constants/phone_verify';
import services from 'services';
import SpinnerIcon from 'components/global/spinner';

const Verify = (props = {}) => {
  const { fields = {}, step, submitForm, setComplete } = props;
  const [isLoading, setIsLoading] = useState(false);

  const {
    phone = '', 
    twilioMethod,
    kvCoreToken,
    email = '',
    name = '',
    address = '',
    city = '',
    state = '',
    postalCode = '',
    kvCoreDomain = '',
    kvCorePPC = '',
    kvCoreAddht = '',
    kvCoreSubid = ''
  } = fields;
  
  const cleanNumber = `1${phone.replace(/[^\d]/g, '')}`;

  const getFirstName = (fullName) => {
    return fullName.split(' ')[0] || fullName;
  };

  const submitToKvCore = async () => {
    try {
      setIsLoading(true);
      
      const leadData = {
        "first_name": getFirstName(name),
        "email": email,
        "cell_phone_1": phone || "",
        "address": address || "",
        "city": city || "",
        "state": state || "",
        "zip": postalCode || "",
        "domain": kvCoreDomain || "",
        "deal_type": "seller",
        "hashtag": kvCoreAddht || "",
        "subid": kvCoreSubid || "",
        "source": kvCorePPC || "",
        "action_note": "Lead Created via Paid Advertising.",
        "byDomain": true
      };
      
      const response = await fetch(`https://api.kvcore.com/market-report/hook/leads/store?token=${kvCoreToken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leadData)
      });
      
      if (!response.ok) {
        console.error('KvCore API error:', response.status, response.statusText);
        throw new Error('Failed to submit lead to KvCore');
      }
      
      const result = await response.json();
      console.log('KvCore lead submission result:', result);
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error('Error submitting lead to KvCore:', error);
      setIsLoading(false);
      return false;
    }
  };

  //focus on the next code input
  useEffect(() => {
    const code = document.querySelectorAll('.code')[0];

    code.focus();

    code.onpaste = function() {
    	setTimeout(function() {
        onSubmit(code.value);
      });
    };
  },[]);

  const handleKeyPress = event => {
    const { value, maxLength } = event.target;
    if (value.length === maxLength) {
      onSubmit(value);
    }
  }
  
  const onSubmit = async (code) => {
    if (code.length === 4) {
      try {
        const {body = {}} = (cleanNumber == PHONE_VERIFY.MOBILE_NUMBER && code == PHONE_VERIFY.VERIFY_CODE) ? {"body":"approved"} :await services.checkTwilioCode(code, cleanNumber);
        if (body === 'approved') {
          setIsLoading(true);
          onCodeFieldStatus(true);
          sendToDataLayer({
            step: (step + 1),
            event: 'funnel_steps',
            fields
          });
          onCodeFieldStatus(true);

          if (kvCoreToken) {
            const kvCoreSuccess = await submitToKvCore();
            if (kvCoreSuccess) {
              console.log('KvCore submission successful');
            } else {
              console.log('KvCore submission failed');
            }
            await submitForm('kvcorehomeval');
            setIsLoading(false);
            setComplete(true);
          } else {
            setIsLoading(false);
            setComplete(true);
            await submitForm('main');
          }
          
        } else {
          onCodeFieldStatus(false);
        }

      } catch (err){
        console.error('Error in verification process:', err);
        onCodeFieldStatus(false);
        setIsLoading(false);
      }

    } else {
      onCodeFieldStatus(false);
    }
  };

  const verify = async () => {
    try {

      const {body = {}} = await services.sendTwilioCode(twilioMethod, cleanNumber).then(response => {
        if (response.body === 'pending') {
          console.log('resent');
        }
      })
      .catch(error => {
        
      });

    } catch (err) {
    
    }
  }

  const [codeField, setCodeField] = useState(true);

  const onCodeFieldStatus = (state) => {
    setCodeField(state);
  }
  
  return (
    <div className='report-form'>
      <h1 className='title'>Please enter the four digit code sent to {hidePhoneNr(phone)}</h1>
      <div className='input-wrapper' id='form'>
        <input
          type="text"
          name="token"
          id="token"
          onChange={handleKeyPress}
          inputMode="numeric"
          autoComplete="one-time-code"
          data-cy = "verify_code"
          className={ codeField ? "code input" : 'code input input--invalid'}
          maxLength="4"
          size="4"
          min="0"
          max="9"
          pattern="[0-9]{1}" />
      </div>
      
      {isLoading ? (
        <div className="flex items-center justify-center">
          <SpinnerIcon className="w-5 h-5" />
        </div>
      ) : (
        <div className="resend-text">
          Didn't receive the code? <a
            className="resend-link"
            onClick={verify}
            href="#"
          >
            Resend Now
          </a>
        </div>
      )}
    </div>
  );
};

export default Verify;