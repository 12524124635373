import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getMainForm } from 'store';
import { SLUG } from 'lib/constants/funnelMeta';
import PropDetails from './propDetails';
import PropDetailsWithVal from './propDetailsWithVal';
import MainForm from 'components/maf/form-wizard';
import * as queryString from 'query-string';
import ValuationRange from './valuationRage';
import ValuationGraph from './valuationGraph';

const Found = (props) => {

  const { fields = {} } = useSelector(getMainForm);

  const {
    intention,
  } = fields;

  const [isStepComplete, setIsStepComplete] = useState(false);

  const checkQueryString = () => {
    const parsed = queryString.parse(window.location.search);
    setIsStepComplete(parsed.step === 'complete');

    if (parsed.step === 'complete') {
      document.getElementById('disclaimer').style.display = 'block';
    } else {
      document.getElementById('disclaimer').style.display = 'none';
    }
  };

  useEffect(() => {
    const originalPushState = window.history.pushState;
    window.history.pushState = function() {
      originalPushState.apply(this, arguments);
      window.dispatchEvent(new Event('pushstate'));
    };

    checkQueryString();

    window.addEventListener('popstate', checkQueryString);
    window.addEventListener('pushstate', checkQueryString);

    return () => {
      window.history.pushState = originalPushState;
      window.removeEventListener('popstate', checkQueryString);
      window.removeEventListener('pushstate', checkQueryString);
    };
  }, []);

  const DetailsComponent = isStepComplete ? PropDetailsWithVal : PropDetails;

  const urlParams = new URLSearchParams(window.location.search);
  const hasDomain = urlParams.has('domain');
  const goBackUrl = hasDomain ? '/home-val' + window.location.search : `/${SLUG.HOMEVAL}`;

  return (
    <div className="report-wrapper">
      <div className='inner-wrapper'>
        <DetailsComponent {...props} intention={intention} />
        {!isStepComplete && <span className="go-back">Not the right property? <a href={goBackUrl}>Go back</a></span>}
      </div>
      {isStepComplete ?
       <div className='inner-wrapper'>
          <div className='valuation-wrapper'>
            <ValuationRange {...props} />
            <ValuationGraph {...props} />
            <div className='about-graph'>
              <h2 className='title'>About This Data</h2>
              <p><strong>Our estimate</strong> is an investor-grade valuation that helps you better estimate the true value of a property. The <strong>Low Range</strong> and <strong>High Range</strong> indicate the confidence interval for our estimate of the subject property.
Our estimate is not a guarantee of a property's value (see Disclaimers for further details).</p>
            </div>
          </div>
        </div> :
        <div className='inner-wrapper'>
          <MainForm isHomeVal={true} />
        </div>
      }
    </div>
  );
};

export default Found;
