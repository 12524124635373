import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getHomeValForm } from 'store';
import NotFound from './notFound';
import Found from './found';

const HomeValReport = () => {

  const { fields = {} } = useSelector(getHomeValForm);

  const {
    address,
  } = fields;

  useEffect(() => {
    if (!address) {
      window.location.href = '/home-valuation';
    }
  }, [address]);

  return (
    <div className={`homeval-report ${!fields.reportFound ? 'not-found' : ''}`}>
      {fields.reportFound ? <Found fields={fields} /> : <NotFound address={address} />}
    </div>
  );  
};

export default HomeValReport;
